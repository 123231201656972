
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthsDnSLc7VLVyycUGjdQ8dAQG_s7az7cxb27RRvHkNHnYMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_932YVgxBWJjPoTX4IPSmR68NXG0m61XBYC4w07sx9F0hQMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as _91IATA_938aUT3HZ1BASSYu29BA8cnxg7tJVv9DK46dC_j8b7Fg8Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue?macro=true";
import { default as _91IATA_93zmaDIoDACb7V0_ceZdUntEPnE9dChPSWY3USdbvc9RMMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue?macro=true";
import { default as _91IATA_935ZEgQ4qkdkGyzTLVUrKBFNiuH_Cu9bzuKpRmyIOiPKQMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue?macro=true";
import { default as _91_46_46_46slug_931br_458Zh_xig6h1w95_O9POE424g7SEjrXpw_459mB2gncMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue?macro=true";
import { default as _91IATA_937wo1j9sT9QH6vgZlMf0QJn84ZOP6qDT1uebf8sM2HLoMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93oQPV0KlEdfvlKckfZauwzpqD60fXTM8j3tiQeRHxszEMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93fDmOb63556ymjBSrlUxoqL7Yb6mOFT7X554hP1H0OcQMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93g57fpToBcAgjbni4fFufM5EXkxbbZQY2lmVCk5FQDeEMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_932GtMWGDYeqbDxyMUkyqmyXw5G7WOGkZzB6u2IAW_YvYMeta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue?macro=true";
import { default as component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0Meta } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0 } from "/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "port-parking-IATA___en",
    path: "/port-parking/:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue")
  },
  {
    name: "airport-lounges-IATA___en",
    path: "/airport-lounges/:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue")
  },
  {
    name: "airport-parking-IATA___en",
    path: "/airport-parking/:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue")
  },
  {
    name: "subdomain-business-slug___en",
    path: "/subdomain/business/:slug(.*)*",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue")
  },
  {
    name: "airport-hotels-with-parking-IATA___en",
    path: "/airport-hotels-with-parking/:IATA()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue")
  },
  {
    name: "port-parking-location-product_slug___en",
    path: "/port-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-lounges-location-product_slug___en",
    path: "/airport-lounges/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue")
  },
  {
    name: "airport-parking-location-product_slug___en",
    path: "/airport-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-hotels-with-parking-location-product_slug___en",
    path: "/airport-hotels-with-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src1253028376/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue")
  },
  {
    name: component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubO1JhcwOq345EuY9QiCAdwPA3wSbXOdnTw39644YZ3p0
  }
]